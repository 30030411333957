<template>
  <div>
    <el-form :model="resource" ref="resourceRef" label-width="80px" class="demo-ruleForm" :size="small">
      <el-row>
        <el-col :span="8">
          <el-form-item label="资源代码">
            <el-input v-model="resource.resourceCode"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="资源名称">
            <el-input v-model="resource.resourceName"></el-input>
          </el-form-item>
        </el-col>
        <!--<el-col :span="8">
          <el-form-item label="是否锁定">
            <el-select v-model="fUser.isLock" placeholder="是否锁定">
              <el-option label="全部" value=""></el-option>
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>-->
      </el-row>

      <el-row>
        <el-button type="primary" icon="el-icon-search" :size="small" @click="findByPage">搜索</el-button>
        <el-button type="primary" icon="el-icon-plus" :size="small" @click="drawer.status=true;drawer.flag='add'">新增资源
        </el-button>
        <!--<el-button type="success" :size="small">成功按钮</el-button>
        <el-button type="info" :size="small">信息按钮</el-button>
        <el-button type="warning" :size="small">警告按钮</el-button>
        <el-button type="danger" :size="small">危险按钮</el-button>-->
      </el-row>
    </el-form>

    <el-table
        row-key="id"
        :data="tableData"
        :height="tableHeight"
        border
        :size="small"
        style="width: 100%;margin-top: 10px;"
        v-loading="loading"
        ref="topictable"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column
          prop="id"
          label="id"
          width="120">
      </el-table-column>
      <el-table-column
          label="资源名称"
          prop="resourceName"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="resourceCode"
          label="资源代码"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="resourceUrl"
          label="资源URL"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="createdTime"
          label="创建时间"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="resourceType"
          label="资源类型"
          :formatter="formatResType"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <!--        <template slot-scope="scope">-->
        <template slot-scope="{row}">
          <el-button type="text" @click="drawer.status=true;drawer.flag='edit';resourceId=row.id" :size="small">编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-drawer
        title=""
        :size="`90%`"
        :destroy-on-close="true"
        :visible.sync="drawer.status"
        :show-close="true"
        v-if="drawer.status"
        ref="drawer"
        :with-header="false">
      <AddResource v-if="drawer.flag === 'add'" :findByPage="findByPage" :drawer="drawer"/>
      <EditResource v-else :findByPage="findByPage" :drawer="drawer" :resourceId="resourceId"
                    :key="new Date().getTime()"/>
    </el-drawer>
  </div>
</template>

<script>
import request from '@/utils/request'
import AddResource from "@/views/resource/AddResource";
import EditResource from "@/views/resource/EditResource";

export default {
  components: {
    AddResource,
    EditResource
  },
  data() {
    return {
      small: 'small',
      tableData: [],
      loading: true,
      tableHeight: null,
      resourceId: '',
      resource: {
        resourceName: '',
        resourceCode: '',
      },
      drawer: {
        status: false,
        flag: ''
      }
    }
  },

  methods: {
    onSubmit() {
      this.findByPage();
    },
    formatResType: function (row) {
      return row.resourceType === 0 ? '菜单' : row.resourceType === 1 ? '链接' : '按钮'
    },
    handleCreate() {
      this.drawer.status = true
    },
    findByPage() {
      this.loading = true

      return request({
        url: `/masterdata/resource/findByTree`,
        baseURL: 'http://127.0.0.1:8093',
        method: 'post',
        contentType: 'application/json',
        data: this.resource
      }).then((response) => {
        this.tableData = response.data;
        this.loading = false;
      })
    }
  }, mounted() {
    this.tableHeight =
        window.innerHeight - this.$refs.topictable.$el.offsetTop - 30;
  }, created() {
    this.findByPage()
  }
}
</script>

<style scoped>
.el-select {
  display: inline-block;
  position: relative;
  width: 100% !important;
}
</style>

